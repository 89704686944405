import React, { Suspense, lazy, useEffect } from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from "react-router-dom";
import "./App.css";
// import Community from "./community";

// Lazy load the components
// const RouteOfPages = lazy(() => import("./route"));
const Home = lazy(() => import("./Home"));
const About = lazy(() => import("./About"));
const Team = lazy(() => import("./Team"));
const Research = lazy(() => import("./Research"));
const Contact = lazy(() => import("./Contact"));
const Token = lazy(() => import("./Token"));
const Technology = lazy(() => import("./Technology"));
const Community = lazy(() => import("./Community"));
const ThinkTank = lazy(() => import("./Think-Tank"));
const PrivacyPolicy = lazy(() => import("./PrivacyPolicy"));
const Projects= lazy(() => import("./Projects"));
const Launchlist = lazy(() => import("./Launchlist"));
const TermsAndConditions = lazy(() => import("./TermsAndConditions"));
const GetInTouch = lazy(() => import("./GetInTouch"));
const ResearchInitiative = lazy(() => import("./ResearchInitiative"));
const BlogPage = lazy(() => import("./Blog"));
const BlogDetailsPage = lazy(() => import("./BlogDetails"));

// Scroll to top only on actual page navigation
const ScrollToTop = () => {
  const location = useLocation();
  const prevLocation = React.useRef(location.pathname);

  useEffect(() => {
    // Scroll to top only if the previous path is different
    if (prevLocation.current !== location.pathname) {
      window.scrollTo(0, 0);
    }
    prevLocation.current = location.pathname; // Update previous location
  }, [location]);

  return null;
};

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <Suspense
        fallback={
          <h1
            style={{
              color: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Loading...
          </h1>
        }
      >
        <Routes>
          {/* <Route path="/" element={<RouteOfPages />} /> */}
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/team" element={<Team />} />
          <Route path="/research" element={<Research />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/token" element={<Token />} />
          <Route path="/technology" element={<Technology />} />
          <Route path="/community" element={<Community />} />
          <Route path="/think-tank" element={<ThinkTank />} />
          <Route path="/launchlist" element={<Launchlist />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-conditions" element={<TermsAndConditions />} />
          <Route path="/research-projects" element={<Projects />} />
          <Route path="/research-collaboration" element={<GetInTouch />} />
          <Route path="/research-initiative" element={<ResearchInitiative />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/blog/:slug" element={<BlogDetailsPage />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
